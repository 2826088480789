export const initialState = null;

const SET_FLOWERS_WITH_BEES = "SET_FLOWERS_WITH_BEES";

export const setFlowersWithBees = (data) => {
  return {
    payload: data,
    type: SET_FLOWERS_WITH_BEES,
  };
};

export const flowersWithBeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLOWERS_WITH_BEES:
      return {
        detail: action.payload,
      };
    default:
      return { detail: state };
  }
};

export default flowersWithBeesReducer;
