export default class FlowerFamGarden {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  getTokens() {
    return this.instance.getNFTs(this.address);
  }

  getTokenDetail(tokenId) {
    return this.instance.flowerGardenSpecs(tokenId);
  }

  balanceOf() {
    return this.instance.balanceOf(this.address);
  }

  getBooster(gardenId) {
    return this.instance.getBooster(gardenId);
  }
}
