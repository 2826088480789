const secondaryHoverClasses = [
  "hover:shadow-sm",
  "transition-shadow",
  "hover:bg-yellow-200",
];

const defaultClasses = [
  "py-2",
  "px-4",
  "gap-2",
  "text-lg",
  "border-4",
  "btn-shadow",
  "select-none",
  "inline-flex",
  "font-morh-b",
  "items-center",
  "rounded-full",
  "cursor-pointer",
  "border-textColor",
];

const primaryHoverClasses = ["hover:shadow-sm", "transition-shadow"];

const secondaryClasses = ["bg-white", "text-textColor"];
const primaryClasses = ["bg-primaryColor", "text-white"];

export default function Button(props) {
  const { children, loading, isPrimary, classes = [], ...defaultProps } = props;
  return (
    <div
      {...defaultProps}
      className={[
        ...defaultClasses,
        ...classes,
        ...(isPrimary ? primaryClasses : secondaryClasses),
        ...(loading
          ? [
              "cursor-not-allowed",
              isPrimary ? "bg-primaryColor/60" : "bg-white/60",
            ]
          : isPrimary
          ? primaryHoverClasses
          : secondaryHoverClasses),
      ].join(" ")}
    >
      {loading ? (
        <svg
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={`animate-spin mr-1 h-5 w-5 ${
            isPrimary ? "text-whhite" : "text-textColor"
          }`}
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            strokeWidth="4"
            stroke="currentColor"
            className="opacity-25"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        ""
      )}
      {children}
    </div>
  );
}
