export default class Honey {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  getBalance() {
    return this.instance.balanceOf(this.address);
  }

  getEcosystemBalance(flowersWithBees) {
    return this.instance.getEcoSystemBalance(this.address, flowersWithBees);
  }

  deposit(signer, amount) {
    return this.instance.connect(signer).sendToEcoSystem(amount);
  }

  withdraw(signer, amount, flowersWithBees) {
    return this.instance
      .connect(signer)
      .takeFromEcoSystem(amount, flowersWithBees);
  }
}
