import React from "react";

import AirdropProofs from "../data/airdropProofs.json";
import { FlowersWithBeesConsumer } from "../context/flowersWithBees";
import { setFlowersWithBees as flowersWithBeesSetter } from "../reducers/flowersWithBees";

import Ecosystem from "adpaters/ecosystem";

const FlowersWithBeesHOC = (BaseComponent) => (props) => {
  const [flowersWithBeesConsumer, dispatch] = FlowersWithBeesConsumer();
  const flowersWithBees = flowersWithBeesConsumer?.detail || {};

  function setFlowersWithBees(data) {
    dispatch(flowersWithBeesSetter(data));
  }

  async function refreshFlowerWithBees(contract, address) {
    const ecosystemObj = new Ecosystem(contract, address);
    const flowers = await ecosystemObj.getAttachedFlowers();
    const newGenFlowers = await ecosystemObj.getAttachedNewGenFlowers();

    const flowersWithBeesObject = {
      fam: flowers.map((i) => i.toString()).filter((i) => parseInt(i)),
      newGen: newGenFlowers.map((i) => i.toString()).filter((i) => parseInt(i)),
    };

    if (
      AirdropProofs &&
      AirdropProofs.proofs &&
      AirdropProofs.proofs[address.toLowerCase()]
    ) {
      flowersWithBeesObject["airdrop"] =
        AirdropProofs.proofs[address.toLowerCase()] || [];
    }

    setFlowersWithBees(flowersWithBeesObject);
  }

  return (
    <BaseComponent
      {...props}
      flowersWithBees={flowersWithBees}
      getFlowerWithBees={(contract, address) =>
        refreshFlowerWithBees(contract, address)
      }
    />
  );
};

export default FlowersWithBeesHOC;
