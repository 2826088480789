/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { ROUTES } from "routes";
import { isEmpty } from "_dash";
import { CONTRACTS } from "global_constants";
import { ethToRegularString, flatFlowersWithBeesObject } from "utils";

import AlertHOC from "hoc/alert";
import Honey from "adpaters/honey";
import ProviderHOC from "hoc/provider";
import FlowerFam from "adpaters/flower";
import useConnect from "hooks/useConnect";
import useProvider from "hooks/useProvider";
import FlowerFamGarden from "adpaters/garden";
import AlertModal from "components/alertModal";
import NewGenFlower from "adpaters/newGenFlower";
import FlowersWithBeesHOC from "hoc/flowersWithBees";
import RouteWithSubRoutes from "components/routeWithSubRoutes";

function App({
  provider,
  setProvider,
  alertMsg,
  alertVisible,
  hideAlert,
  flowersWithBees,
  getFlowerWithBees,
}) {
  const contracts = useProvider(provider);
  const [, , , connection, doAutoConnect] = useConnect(provider);

  const [loading, setLoading] = useState(true);
  const [assetsCount, setAssetsCount] = useState(0);

  useEffect(() => {
    doAutoConnect();
  }, []);

  useEffect(() => {
    if (connection) {
      setProvider(connection);
    }
  }, [connection]);

  useEffect(() => {
    if (!isEmpty(contracts)) {
      getFlowerWithBees(contracts[CONTRACTS.ECOSYSTEM], provider.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts]);

  useEffect(() => {
    if (!isEmpty(contracts) && !isEmpty(flowersWithBees)) {
      (async function () {
        let accountBalance = 0;
        const flowerObj = new FlowerFam(
          contracts[CONTRACTS.FLOWER],
          provider.address
        );
        const gardenObj = new FlowerFamGarden(
          contracts[CONTRACTS.GARDEN],
          provider.address
        );
        const newGenFlowerObj = new NewGenFlower(
          contracts[CONTRACTS.NEWGENFLOWER],
          provider.address
        );
        const honeyObj = new Honey(
          contracts[CONTRACTS.HONEY],
          provider.address
        );
        const gardenBalance = await gardenObj.balanceOf();
        const flowerBalance = await flowerObj.balanceOf();
        const newGenFlowerBalance = await newGenFlowerObj.balanceOf();

        try {
          const honeyBalance = await honeyObj.getBalance();
          const ecoSystemBalance = await honeyObj.getEcosystemBalance(
            flatFlowersWithBeesObject(flowersWithBees)
          );
          accountBalance = parseInt(
            ethToRegularString(honeyBalance.add(ecoSystemBalance))
          );
        } catch (e) {}

        setAssetsCount(
          parseInt(newGenFlowerBalance) +
            parseInt(flowerBalance) +
            parseInt(accountBalance) +
            parseInt(gardenBalance)
        );

        setLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts, flowersWithBees]);

  return (
    <>
      <Router>
        <Switch>
          {ROUTES.map((route, i) => {
            return (
              <RouteWithSubRoutes
                key={i}
                {...route}
                appLoading={loading}
                assetsCount={assetsCount}
              />
            );
          })}
        </Switch>
      </Router>
      <AlertModal
        message={alertMsg}
        visible={alertVisible}
        onClose={() => hideAlert()}
      />
    </>
  );
}

export default FlowersWithBeesHOC(ProviderHOC(AlertHOC(App)));
