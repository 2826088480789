import React from "react";

import { AlertConsumer } from "context/alert";
import { setAlert as alertSetter } from "reducers/alert";
import { ERROR_MESSAGES } from "global_constants";

const AlertHOC = (BaseComponent) => (props) => {
  const [alertConsumer, dispatch] = AlertConsumer();
  const alertDetail = alertConsumer?.detail || {};

  function showAlert(msg) {
    let mapMessage = null;
    const defaultMsg = `An unexpected error occurred and the transaction failed. 
    Check your wallet or etherscan to see what happened.`;

    for (const error of ERROR_MESSAGES) {
      if (msg.includes(error.error) || error.error.includes(msg)) {
        mapMessage = error.message;
      }
    }
    if (mapMessage) {
      msg = mapMessage;
    } else {
      if (msg.length > 200) {
        msg = defaultMsg;
      }
    }

    dispatch(
      alertSetter({
        msg,
        visible: true,
      })
    );
  }

  function hideAlert() {
    dispatch(
      alertSetter({
        visible: false,
      })
    );
  }

  return (
    <BaseComponent
      {...props}
      alertMsg={alertDetail.msg}
      hideAlert={() => hideAlert()}
      alertVisible={alertDetail.visible}
      showAlert={(msg) => showAlert(msg)}
    />
  );
};

export default AlertHOC;
