import { lazy } from "react";

import AppLayout from "layout";

import bankBgImg from "assets/graphics/bank-bg.png";
import gardenBgImg from "assets/graphics/garden-bg.png";
import arcadeBgImg from "assets/graphics/arcade-bg.png";
import casinoBgImg from "assets/graphics/casino-bg.png";
import checkerBgImg from "assets/graphics/checker-bg.jpg";
import oasisCloudsImg from "assets/graphics/oasis-clouds.png";
import alterBgImg from "assets/graphics/altar-bg-final-4k.jpg";

export const INDEX_ROUTE = "/";
export const FAQ_ROUTE = "/faq/";
export const BANK_ROUTE = "/bank/";
export const ALTAR_ROUTE = "/altar/";
export const CASINO_ROUTE = "/casino/";
export const RAFFLE_ROUTE = "/raffle/";
export const GARDEN_ROUTE = "/garden/";
export const ARCADE_ROUTE = "/arcade/";
export const MARKET_ROUTE = "/market/";
export const CHECKER_ROUTE = "/checker/";
export const OASIS_LAND_ROUTE = "/oasis-lands/";
export const BOB_SHOP_ROUTE = "/bob-shop/";

const FAQComponent = lazy(() => import("pages/faq"));
const BankComponent = lazy(() => import("pages/bank"));
const AltarComponent = lazy(() => import("pages/altar"));
const OasisComponent = lazy(() => import("pages/oasis"));
const RaffleComponent = lazy(() => import("pages/raffle"));
const GardenComponent = lazy(() => import("pages/garden"));
const MarketComponent = lazy(() => import("pages/market"));
const ArcadeComponent = lazy(() => import("pages/arcade"));
const CasinoComponent = lazy(() => import("pages/casino"));
const CheckerComponent = lazy(() => import("pages/checker"));
const OasisLandComponent = lazy(() => import("pages/oasisLand"));
const MarketplaceComponent = lazy(() => import("pages/marketplace"));

export const ROUTES = [
  {
    path: "/",
    name: "index",
    component: AppLayout,
    routes: [
      {
        exact: true,
        name: "index",
        path: INDEX_ROUTE,
        component: OasisComponent,
      },
      {
        exact: true,
        name: "bank",
        path: BANK_ROUTE,
        bgImg: bankBgImg,
        component: BankComponent,
      },
      {
        exact: true,
        name: "Garden",
        path: GARDEN_ROUTE,
        bgImg: gardenBgImg,
        component: GardenComponent,
      },
      {
        exact: true,
        name: "Market",
        bgImg: bankBgImg,
        path: MARKET_ROUTE,
        component: MarketComponent,
      },
      {
        exact: true,
        name: "Checker",
        bgSize: "cover",
        bgImg: checkerBgImg,
        path: CHECKER_ROUTE,
        component: CheckerComponent,
      },
      {
        exact: true,
        name: "Faq",
        path: FAQ_ROUTE,
        bgImg: checkerBgImg,
        component: FAQComponent,
      },
      {
        exact: true,
        isCover: true,
        name: "Raffle",
        bgImg: checkerBgImg,
        bgSize: "cover",
        path: RAFFLE_ROUTE,
        component: RaffleComponent,
      },
      {
        exact: true,
        name: "OasisLand",
        bgSize: "100% 900px",
        bgImg: oasisCloudsImg,
        path: OASIS_LAND_ROUTE,
        component: OasisLandComponent,
        containerClasses: ["backdrop-blur-sm", "bg-white/10"],
      },
      {
        exact: true,
        name: "Alter",
        bgSize: "cover",
        bgImg: alterBgImg,
        path: ALTAR_ROUTE,
        component: AltarComponent,
      },
      {
        exact: true,
        isCover: true,
        bgSize: "cover",
        name: "Marketplace",
        bgImg: checkerBgImg,
        path: BOB_SHOP_ROUTE,
        component: MarketplaceComponent,
      },
      {
        exact: true,
        isCover: true,
        bgSize: "cover",
        name: "Arcade",
        bgImg: arcadeBgImg,
        path: ARCADE_ROUTE,
        component: ArcadeComponent,
        containerClasses: ["backdrop-blur-sm", "bg-white/10"],
      },
      {
        exact: true,
        name: "Casino",
        bgImg: casinoBgImg,
        path: CASINO_ROUTE,
        component: CasinoComponent,
      },
    ],
  },
];
