import { useEffect } from "react";

import Button from "components/button";
import ProviderHOC from "hoc/provider";
import useConnect from "hooks/useConnect";

function WalletConnectBtn({
  classes = [],
  isPrimary,
  provider,
  setProvider,
  children,
}) {
  const [, isConnecting, doConnect, connection, , doDisconnect] =
    useConnect(provider);

  useEffect(() => {
    setProvider(connection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  return (
    <Button
      classes={classes}
      isPrimary={isPrimary}
      loading={isConnecting}
      onClick={() => (!!provider ? doDisconnect() : doConnect())}
    >
      {children}
    </Button>
  );
}

export default ProviderHOC(WalletConnectBtn);
