import { useHistory } from "react-router-dom";

import * as routes from "routes";
import { URLS } from "global_constants";

import Button from "components/button";
import ProviderHOC from "hoc/provider";
import WalletConnectBtn from "components/walletConnectBtn";

import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as Gitlab } from "assets/graphics/gitlab.svg";
import { ReactComponent as Medium } from "assets/graphics/medium.svg";
import { ReactComponent as Twitter } from "assets/graphics/twitter.svg";
import { ReactComponent as Discord } from "assets/graphics/discord.svg";

function Header({ hasFlowers, showNoFlowerModal, isConnected }) {
  const history = useHistory();

  function onLinkItemClick(to) {
    if (hasFlowers) {
      history.push(to);
    } else {
      showNoFlowerModal();
    }
  }

  return (
    <header className="container m-auto flex items-center bg-white relative rounded-full justify-between py-4 px-6 self-center w-full my-6 mb-8 z-50">
      <div className="flex items-center">
        <Logo
          className="mr-12 cursor-pointer"
          onClick={() => onLinkItemClick(routes.INDEX_ROUTE)}
        />
        <ul className="flex text-lg font-morh-b gap-6 pr-6 mr-6 leading-none items-center border-r-2 border-[#D4D4D4]">
          <li
            className="hover:opacity-50 cursor-pointer"
            onClick={() => onLinkItemClick(routes.GARDEN_ROUTE)}
          >
            My Oasis
          </li>
          <li
            className="hover:opacity-50 cursor-pointer"
            onClick={() => onLinkItemClick(routes.BANK_ROUTE)}
          >
            Bank
          </li>
          <li
            className="hover:opacity-50 cursor-pointer"
            onClick={() => onLinkItemClick(routes.MARKET_ROUTE)}
          >
            Market
          </li>

          <li
            className="hover:opacity-50 cursor-pointer"
            onClick={() => onLinkItemClick(routes.BOB_SHOP_ROUTE)}
          >
            Bob's Shop
          </li>
          <li
            className="hover:opacity-50 cursor-pointer"
            onClick={() => history.push(routes.RAFFLE_ROUTE)}
          >
            Raffle
          </li>
        </ul>
        <ul className="flex text-lg font-morh-b gap-6 leading-none items-center">
          <li
            className="hover:opacity-50 cursor-pointer"
            onClick={() => onLinkItemClick(routes.CHECKER_ROUTE)}
          >
            Checker
          </li>
          <li
            className="hover:opacity-50 cursor-pointer"
            onClick={() => window.open(URLS.FAQ, "_blank")}
          >
            FAQ
          </li>
        </ul>
      </div>
      <div className="flex items-center gap-6">
        <div className="flex gap-4">
          <Button
            onClick={() => window.open(URLS.MEDIUM, "_blank")}
            classes={["w-[36px]", "h-[36px]", "!p-0", "justify-center"]}
          >
            <Medium />
          </Button>
          <Button
            onClick={() => window.open(URLS.GITBOOK, "_blank")}
            classes={["w-[36px]", "h-[36px]", "!p-0", "justify-center"]}
          >
            <Gitlab className="h-[36px] w-[36px] p-1" />
          </Button>
          <Button
            onClick={() => window.open(URLS.DISCORD, "_blank")}
            classes={["w-[36px]", "h-[36px]", "!p-0", "justify-center"]}
          >
            <Discord />
          </Button>
          <Button
            onClick={() => window.open(URLS.TWITTER, "_blank")}
            classes={["w-[36px]", "h-[36px]", "!p-0", "justify-center"]}
          >
            <Twitter />
          </Button>
        </div>
        <WalletConnectBtn
          isPrimary={isConnected}
          classes={isConnected ? ["uppercase", "!px-10"] : []}
        >
          {isConnected ? "Disconnect" : "Connect wallet"}
        </WalletConnectBtn>
      </div>
    </header>
  );
}

export default ProviderHOC(Header);
