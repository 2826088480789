export const NETWORK = {
  TARGET_CHAIN_ID: 1,
  MAINNET_CHAIN_ID: 1,
  CHAIN_ID_TO_NAME: {
    1: "mainnet",
    3: "ropsten",
    4: "rinkeby",
    42: "kovan",
    1337: "localhost",
  },
  CHAIN_ID_TO_STABLE_COIN: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    42: "0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa",
  },
  INFURA_ID: "632b4850f79f4f7f9ba2468897bdcbc0",
};

export const CONTRACTS = {
  BEE: "Bee",
  HONEY: "Honey",
  FLOWER: "FlowerFam",
  OASIS: "FlowerFamOasis",
  RAFFLE: "HoneyCoinRaffle",
  GARDEN: "FlowerFamGarden",
  ARCADE: "HoneyCoinArcade",
  RESERVE: "HoneyCoinReserve",
  ECOSYSTEM: "FlowerFamEcoSystem",
  NEWGENFLOWER: "FlowerFamNewGen",
  RANDOMIZER: "FlowerFamRandomizer",
  MARKETPLACE: "FlowerFamMarketPlace",
  GARDENRANDOMIZER: "GardenRandomizer",
  HCMARKETPLACE: "HoneyCoinMarketPlace",
  GARDENECOSYSTEM: "FlowerFamGardenEcoSystem",
};

export const MORALIS = {
  CREDS: [
    {
      APP_ID: "l2dkcQuvB1vvInnA99a03SxpvM5XotUXNwUHtBKu",
      SERVER_URL: "https://chnjzednf0ro.usemoralis.com:2053/server",
    },
    {
      APP_ID: "KyBtbDOP2kTksGt0wk1heHUrxl0mJqfdqXVOEYeK",
      SERVER_URL: "https://wr39z5rslhh3.usemoralis.com:2053/server",
    },
    {
      APP_ID: "BPsfbad1ZEdVAnMkJmCs5Me2Gd4UnX2zH66aBF4Q",
      SERVER_URL: "https://pjaxjcjmxfmy.usemoralis.com:2053/server",
    },
    {
      APP_ID: "AjhoycArT2HHMHR1WmnUEiApJk3Q1YYeK1QqeurM",
      SERVER_URL: "https://9tb5e0nuzwrj.usemoralis.com:2053/server",
    },
  ],
};

export const FLOWER_FAM_MAPPING = {
  0: {
    name: "Prodigies",
    coins_per_day: 4,
    upgrade_honey_coins_price: 53.6,
  },
  1: {
    name: "Seedlings",
    coins_per_day: 6,
    upgrade_honey_coins_price: 107.1,
  },
  2: {
    name: "Ancestors",
    coins_per_day: 10,
    upgrade_honey_coins_price: 150,
  },
  3: {
    name: "Elders",
    coins_per_day: 18,
    upgrade_honey_coins_price: 428.6,
  },
  4: {
    name: "Pioneers",
    coins_per_day: 30,
    upgrade_honey_coins_price: 750,
  },
};

export const NEW_GEN_FLOWER = {
  name: "New gen",
  coins_per_day: 2,
};

export const BEE_PRICE = 2000;
export const SEED_PRICE = 1000;
export const GARDEN_GENERATE_PRICE = 69;
export const FLOWER_UPGRADE_PRICE = 53.6;
export const GARDEN_BLESSING_LEVELS = [
  {
    reward: 10,
    price: 6,
  },
  {
    reward: 20,
    price: 11,
  },
  {
    reward: 50,
    price: 25,
  },
  {
    reward: 100,
    price: 45,
  },
];

export const URLS = {
  DISCORD: "http://discord.gg/FlowerFam",
  MEDIUM: "https://flowerfam.medium.com/",
  GITBOOK: "https://docs.flowerfam.earth/",
  WEBSITE: "https://www.flowerfam.earth/",
  TWITTER: "https://twitter.com/FlowerFamNFT",
  SNAPSHOT: "https://snapshot.org/#/dao-flowerfam.eth",
  DISCORD_INVITE: "https://discord.com/invite/flowerfam",
  BEE_OPEANSEA: "https://opensea.io/collection/flower-fam-bees",
  OPEANSEA: "https://opensea.io/collection/flowerfam-fam-official",
  FAQ: `https://docs.flowerfam.earth/before-you-leave.../faqs`,
  NEWGEN_OPENSEA: "https://opensea.io/collection/flower-fam-new-generation",
  CATCH_BEE_DOC: `https://docs.flowerfam.earth/the-oasis/catching-bees`,
  BUY_SEED_DOC: `https://docs.flowerfam.earth/the-oasis/new-generation-flowers`,
  UPGRADE_FLOWER_DOC: `https://docs.flowerfam.earth/the-oasis/upgrading-your-flower`,
  HC_UNISWAP_URL: `https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=0x0e239dB593619bcF6248FdeF4723f26cf40e1f37`,
};

export const LS_MAPPING_KEY = "64bf9bd121662f86d2760cf1f95e8c7b";
export const LS_AFFILIATE_KEY = "95ba24e4e235e76d8fbcedd939fc558a";

export const getEtherscanTxUrl = (hash) => `https://etherscan.io/tx/${hash}`;

export const ERROR_MESSAGES = [
  {
    error: "MetaMask Tx Signature: User denied transaction signature",
    message: "Error: You rejected the transaction.",
  },
  {
    error: "insufficient funds for gas * price + value",
    message: `Error: You don't have enough Ether in your wallet to pay for gas fees.`,
  },
  {
    error: "BALANCE TOO LOW",
    message: `If you just bought honey coin head to the bank to convert them and spend them here`,
  },
  {
    error: "Eco system balance too low",
    message: `If you just bought honey coin head to the bank to convert them and spend them here`,
  },
  {
    error: "Project does not exist",
    message: `Error: This project does not seem to be available at this time`,
  },
  {
    error: "Not enough spots left",
    message: `Error: Not enough supply left for your purchase`,
  },
  {
    error: "Project offering expired",
    message: `Error: Offer has expired and will be removed.`,
  },
  {
    error: "Address already reserve the spot",
    message: `Error: Your claim exceeds the maximum spots per user limit.`,
  },
];

export const OASIS_ASSET_REWARDS = [
  ["Staked on Oasis lands", "Extra Yield"],
  ["1 Flower", "+10%"],
  ["2 Flower", "+20%"],
  ["4 Flower", "+25%"],
  ["6 Flower", "30%"],
  ["8 Flower", "35%"],
  ["12+ Flower", "40%"],
];

export const OASIS_LAND_REWARDS = [
  ["Owned Lands", "Earning boost"],
  ["2 Lands", "+1%"],
  ["4 Lands", "+2%"],
  ["8 Lands", "+3%"],
  ["10 Lands", "+4%"],
  ["20 Lands", "+6%"],
  ["30 Lands", "+8%"],
  ["40 Lands", "+10%"],
  ["50 Lands", "+12%"],
  ["60 Lands", "+14%"],
  ["69 Lands", "+16.9%"],
];

export const OASIS_UNCLAIMED_REWARDS = [
  ["Hodl time", "Honey bonus"],
  ["4 days", "+3%"],
  ["1 week", "+5%"],
  ["2 weeks", "+8%"],
  ["1 month", "+10%"],
  ["2 months", "+12%"],
  ["3 months", "+16%"],
  ["4 months", "+20%"],
];

export const ONE_WEEK_IN_SEC = 604800;

export const ZERO_ADDR = "0x0000000000000000000000000000000000000000";

export const GARDEN_MAPPING = {
  0: {
    name: "Misty Peaks",
  },
  1: {
    name: "Beeos's Rainforest",
  },
  2: {
    name: "Lily's Pond",
  },
  3: {
    name: "Mystic Babylon",
  },
};
