import { ethers } from "ethers";
import { ZERO_ADDR } from "global_constants";

export const twoDecimals = (str) => {
  const split = str.split(".");
  if (split.length > 1) {
    return split[0] + "." + split[1].substring(0, 2);
  } else {
    return str;
  }
};

export const ethToRegularString = (eth, force = false) => {
  if (!force && (!eth || !ethers.BigNumber.isBigNumber(eth))) {
    return "0.0";
  }
  return twoDecimals(ethers.utils.formatEther(eth));
};

export const flatFlowersWithBeesObject = (data) => {
  let airdrop = [];
  const flowersWithBees = {
    ...(data.fam ? { fam: data.fam } : {}),
    ...(data.newGen ? { newGen: data.newGen } : {}),
  };

  const values = [].concat.apply([], Object.values(flowersWithBees));

  values.sort((a, b) => {
    return a - b;
  });

  if (data.airdrop) {
    airdrop.push(data.airdrop[1]);
    airdrop = airdrop.concat(data.airdrop[0]);
  }

  return values.concat(airdrop);
};

export const toHCBN = (amount) => {
  return ethers.BigNumber.from(Math.floor(amount * 10000)).mul(
    ethers.BigNumber.from(10).pow(14)
  );
};

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const getEpochDaysDiff = (epochValue) => {
  const diff = new Date().getTime() / 1000 - epochValue;
  return Math.abs(Math.ceil(diff / 1000 / 60 / 60 / 24));
};

export const getMinimumGasLimit = (estimatedGas) => {
  const defaultLimit = 325000;
  if (estimatedGas.lt(defaultLimit)) {
    estimatedGas = ethers.BigNumber.from(defaultLimit);
  }
  return estimatedGas;
};

export const validateDiscord = (discord) => {
  return String(discord)
    .toLowerCase()
    .match(/^.{3,32}#[0-9]{4}$/);
};

export const isZeroAddress = (addr) => {
  return addr === ZERO_ADDR;
};

export const getFlowerUrl = (flowerId) => {
  return (
    flowerId &&
    `https://storage.googleapis.com/flowerfam/assets/flowerfam/${flowerId}.png`
  );
};

export const getBeeUrl = (beeId) => {
  return (
    beeId && `https://storage.googleapis.com/flowerfam/assets/bees/${beeId}.png`
  );
};

export const getNewGenFlowerUrl = (flowerId) => {
  return (
    flowerId &&
    `https://storage.googleapis.com/flowerfam/assets/newgen/${flowerId}.png`
  );
};

export const getGardenUrl = (gardenId) => {
  return (
    gardenId &&
    `https://storage.googleapis.com/flowerfam/assets/garden/${gardenId}.png`
  );
};
