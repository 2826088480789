import { Suspense } from "react";
import { Switch } from "react-router-dom";

import RouteWithSubRoutes from "../routeWithSubRoutes";

const RenderRoutes = (props) => {
  const { routes, ...otherProps } = props;
  return (
    <Suspense fallback={<div className="app-loader"></div>}>
      <Switch>
        {routes.map((route, i) => {
          return (
            <RouteWithSubRoutes key={route.name} {...route} {...otherProps} />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default RenderRoutes;
