export default class Ecosystem {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  getUserProduction() {
    return this.instance.userToProductionInfo(this.address);
  }

  getNotAccumulatedUserProduction(flowersWithBees) {
    return this.instance.getTotalNotAccumulatedProductionOfUser(
      this.address,
      flowersWithBees
    );
  }

  getUserTotalHoneyCount(flowersWithBees) {
    return this.instance.getTotalProductionOfUser(
      this.address,
      flowersWithBees
    );
  }

  getFlowerAttachedBee(flowerId) {
    return this.instance.flowerToBee(flowerId);
  }

  getNewGenFlowerAttachedBee(flowerId) {
    return this.instance.newGenFlowerToBee(flowerId);
  }

  getAttachedFlower(beeId) {
    return this.instance.getAttachedFlowerOfBee(beeId);
  }

  getAttachedNewGenFlower(beeId) {
    return this.instance.getAttachedNewGenFlowerOfBee(beeId);
  }

  getAttachedFlowers() {
    return this.instance.getFlowerFamFlowersOfUserWithBees(this.address);
  }

  getAttachedNewGenFlowers() {
    return this.instance.getNewGenFlowersOfUserWithBees(this.address);
  }

  claimNotAccumulatedUserProduction(signer, flowersWithBees) {
    return this.instance
      .connect(signer)
      .claimTotalNotAccumulatedProductionOfUser(this.address, flowersWithBees);
  }

  stakeFlower(signer, flowerId) {
    return this.instance.connect(signer).stakeFlowerFamFlower(flowerId);
  }

  unstakeFlower(signer, flowerId) {
    return this.instance.connect(signer).unstakeFlowerFamFlower(flowerId);
  }

  batchStakeFlowers(signer, flowerIds) {
    return this.instance.connect(signer).batchStakeFlowerFamFlowers(flowerIds);
  }

  batchUnstakeFlowers(signer, flowerIds) {
    return this.instance.connect(signer).batchUnstakeFlowerFamFlower(flowerIds);
  }

  stakeNewGenFlower(signer, flowerId) {
    return this.instance.connect(signer).stakeNewGenerationFlower(flowerId);
  }

  unstakeNewGenFlower(signer, flowerId) {
    return this.instance.connect(signer).unstakeNewGenerationFlower(flowerId);
  }

  batchStakeNewGenFlowers(signer, flowerIds) {
    return this.instance
      .connect(signer)
      .batchStakeNewGenerationFlower(flowerIds);
  }

  batchUnstakeNewGenFlowers(signer, flowerIds) {
    return this.instance
      .connect(signer)
      .batchUnstakeNewGenerationFlower(flowerIds);
  }

  attachBee(signer, beeId, flowerId) {
    return this.instance.connect(signer).attachBeeToFlower(flowerId, beeId);
  }

  releaseBee(signer, beeId, flowerId) {
    return this.instance.connect(signer).releaseBeeFromFlower(flowerId, beeId);
  }

  attachBeeToNewGenFlower(signer, beeId, flowerId) {
    return this.instance
      .connect(signer)
      .attachBeeToNewGenFlower(flowerId, beeId);
  }

  releaseBeeToNewGenFlower(signer, beeId, flowerId) {
    return this.instance
      .connect(signer)
      .releaseBeeFromNewGenFlower(flowerId, beeId);
  }

  speciesToHoneyProduction(species) {
    return this.instance.speciesToHoneyProduction(species);
  }

  newGenHoneyProduction() {
    return this.instance.newGenHoneyProduction();
  }
}
