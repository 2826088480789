import { Route } from "react-router-dom";

const RouteWithSubRoutes = (props) => {
  const { path, routes, ...otherProps } = props;
  return (
    <Route
      path={path}
      render={(routeProps) => (
        // pass the sub-routes down to keep nesting
        <props.component {...routeProps} {...otherProps} routes={routes} />
      )}
    />
  );
};

export default RouteWithSubRoutes;
