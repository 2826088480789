/*eslint-disable*/
import React, { useReducer, useContext, createContext } from "react";

const AlertContext = createContext();

export const AlertProvider = ({ reducer, initialState, children }) => {
  const contextValue = useReducer(reducer, initialState);
  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
};

export const AlertConsumer = () => {
  const contextValue = useContext(AlertContext);
  return contextValue;
};
