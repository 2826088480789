import { matchPath } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { memo, useEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { LS_AFFILIATE_KEY, URLS } from "global_constants";
import { INDEX_ROUTE, BOB_SHOP_ROUTE, RAFFLE_ROUTE } from "routes";

import AccountHOC from "hoc/account";
import Header from "components/header";
import Button from "components/button";
import ProviderHOC from "hoc/provider";
import useFirebase from "hooks/useFirebase";
import skyImg from "assets/graphics/sky.jpg";
import RenderRoutes from "components/renderRoutes";
import NoFlowerModal from "components/noFlowerModal";
import UserSettingsModal from "components/userSettingsModal";

import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as NoticeSvg } from "assets/graphics/notice.svg";

function getScreenWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

function AppLayout({
  provider,
  appLoading,
  assetsCount,
  routes,
  location,
  history,
  accountData,
  setAccountData,
  toggleSettingsModal,
}) {
  const [firestore] = useFirebase();

  const [bgImg, setBgImg] = useState();
  const [bgSize, setBgSize] = useState(false);
  const [containerClasses, setContainerClasses] = useState([]);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [noFlowerModalVisible, setNoFlowerModalVisible] = useState(false);

  useEffect(() => {
    const isRaffleRoute = !!matchPath(location.pathname, {
      exact: true,
      path: RAFFLE_ROUTE,
    });
    const isMarketplaceRoute = !!matchPath(location.pathname, {
      exact: true,
      path: BOB_SHOP_ROUTE,
    });
    if (!appLoading && !assetsCount && !isRaffleRoute && !isMarketplaceRoute) {
      setNoFlowerModalVisible(true);
    }
  }, [appLoading, assetsCount, location.pathname]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const inviteAddr = urlSearchParams.get("invite") || "";
    if (inviteAddr) {
      localStorage.setItem(LS_AFFILIATE_KEY, inviteAddr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let bgSize = "";
    let newBgImg = null;
    let containerClasses = [];

    routes.forEach((item) => {
      const matchedPath = matchPath(location.pathname, {
        exact: true,
        path: item.path,
      });
      if (matchedPath) {
        newBgImg = item.bgImg;
        bgSize = item.bgSize;
        containerClasses = item.containerClasses || [];
      }
    });

    setBgImg(newBgImg);
    setBgSize(bgSize);
    setContainerClasses(containerClasses);
  }, [location.pathname, routes]);

  useEffect(() => {
    if (provider?.address) {
      getDoc(doc(firestore, "flowerfam_user_settings", provider.address)).then(
        (snap) => {
          if (snap.exists()) {
            setAccountData(snap.data());
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore, provider?.address]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        setScreenWidth(getScreenWidth());
      },
      true
    );
  });

  return (
    <>
      <div
        className={`bg-no-repeat relative`}
        style={{
          backgroundPositionX: "center",
          backgroundSize: bgSize || "100% auto",
          backgroundImage: `url(${bgImg || skyImg})`,
          // backgroundSize: isBgCover ? "cover" : "100% auto",
        }}
      >
        {screenWidth > 1024 ? (
          <div
            className={`pb-0 flex flex-col min-h-screen ${containerClasses.join(
              " "
            )}`}
          >
            <Header
              hasFlowers={!appLoading && assetsCount}
              showNoFlowerModal={() => setNoFlowerModalVisible(true)}
            />
            <main className="flex-1 flex flex-col">
              <TransitionGroup component={null}>
                <CSSTransition
                  timeout={300}
                  classNames="fade"
                  key={location.pathname}
                >
                  <RenderRoutes routes={routes} />
                </CSSTransition>
              </TransitionGroup>
            </main>
          </div>
        ) : (
          <div className="pt-10 pb-0 px-4 container m-auto flex flex-col min-h-screen justify-between">
            <header className="flex items-center bg-white relative rounded-full justify-between py-4 px-6 self-center w-full">
              <Logo
                className="mr-12 cursor-pointer"
                onClick={() => history.push(INDEX_ROUTE)}
              />
            </header>
            <div className="relative">
              <NoticeSvg className="m-auto" />
              <p className="font-morh-sb gap-1 items-center absolute max-w-[240px] flex-col bottom-[190px] left-1/2 ml-[-120px] text-center rotate-[-3deg]">
                <span className="font-morh-sb">
                  Metamask Mobile + Staking&nbsp;
                </span>
                <span>
                  doesn't offer the best experience. The Oasis is only&nbsp;
                </span>
                <span className="font-morh-sb">
                  available on Desktop. Try again later
                </span>
              </p>
              <Button
                onClick={() => window.open(URLS.GITBOOK, "_blank")}
                classes={[
                  "absolute",
                  "bottom-[90px]",
                  "left-1/2",
                  "ml-[-100px]",
                ]}
              >
                Read our gitbook
              </Button>
            </div>
          </div>
        )}
      </div>
      <NoFlowerModal visible={noFlowerModalVisible} onClose={() => {}} />
      <UserSettingsModal
        provider={provider}
        userSettings={accountData}
        visible={!!accountData.visible}
        onUpdate={(data) => {
          toggleSettingsModal();
          setAccountData({ ...data });
        }}
        onClose={() => toggleSettingsModal()}
      />
    </>
  );
}

export default memo(ProviderHOC(AccountHOC(AppLayout)));
