import Modal from "react-awesome-modal";
import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";

import { isEmpty } from "_dash";
import { getFlowerUrl } from "utils";
import { CONTRACTS } from "global_constants";

import Button from "components/button";
import FlowerFam from "adpaters/flower";
import useProvider from "hooks/useProvider";
import useFirebase from "hooks/useFirebase";

function UserSettingsModal({
  userSettings,
  visible,
  onClose,
  onUpdate,
  provider,
}) {
  const [firestore] = useFirebase();
  const contracts = useProvider(provider);

  const [userName, setUserName] = useState("");
  const [saving, setSaving] = useState(false);
  const [flowerTokens, setFlowerTokens] = useState([]);
  const [selectedFlowerId, setSelectedFlowerId] = useState();

  useEffect(() => {
    if (provider?.address) {
      setSelectedFlowerId(userSettings.imgId);
      setUserName(userSettings.name || provider?.address);
    }
  }, [provider?.address, userSettings]);

  useEffect(() => {
    if (!isEmpty(contracts)) {
      const flowerObj = new FlowerFam(
        contracts[CONTRACTS.FLOWER],
        provider.address
      );
      flowerObj.getTokens().then((response) => {
        const data = response.map((item) => {
          return (({ flowerId }) => ({
            flowerId,
          }))(item);
        });
        setFlowerTokens(data);
      });
    }
  }, [contracts, provider?.address]);

  async function onSave() {
    setSaving(true);
    const data = {
      name: userName || provider.address,
      imgId: selectedFlowerId,
    };
    await setDoc(
      doc(firestore, "flowerfam_user_settings", provider.address),
      data
    );
    onUpdate(data);
    setSaving(false);
  }

  return (
    <div className="modal relative">
      <Modal
        width="580"
        visible={visible}
        effect="fadeInUp"
        onClickAway={() => onClose()}
      >
        <p className="text-6xl font-arco relative block title z-10 text-center top-[-100px] mb-[-70px]">
          <span className="text-white">My</span>
          <span className="block text-[#FFEC3E]">settings</span>
        </p>
        <div className="text-center mb-10">
          <p className="text-lg font-morh-sb mb-2">Profile name</p>
          <input
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="w-[160px] border-2 border-textColor rounded-lg py-1 px-4 outline-none text-lg font-morh-sb"
          />
        </div>
        <div className="text-center pb-12">
          <p className="text-lg font-morh-sb">Select profile picture</p>
          <div className="flex max-h-[400px] flex-wrap overflow-scroll mt-4 gap-4 px-6 justify-center">
            {flowerTokens.map((item) => {
              const flowerId = item.flowerId.toString();
              return (
                <div
                  key={flowerId}
                  className="w-[160px] relative cursor-pointer"
                  onClick={() => setSelectedFlowerId(flowerId)}
                >
                  <img
                    alt={flowerId}
                    className="m-auto rounded-xl"
                    src={getFlowerUrl(flowerId)}
                  />
                  <input
                    type="checkbox"
                    checked={selectedFlowerId === flowerId}
                    onChange={(e) => setSelectedFlowerId(flowerId)}
                    className="absolute top-3 right-3 z-10 w-[16px] h-[16px]"
                  />
                </div>
              );
            })}
          </div>
        </div>
        <Button
          isPrimary
          loading={saving}
          onClick={() => onSave()}
          classes={["absolute", "bottom-[20px]", "left-1/2", "ml-[-40px]"]}
        >
          Save
        </Button>
      </Modal>
    </div>
  );
}

export default UserSettingsModal;
